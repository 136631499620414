<template>
  <v-container fluid app>
    <page-title title="Digital Ecosystem" :useBackBtn="true"></page-title>

    <div style="padding-top: 10px" class="flex justify-content-center padding">

      <v-row>
        <v-col cols="12" md="12">
          <v-subheader>Institutions benefits from Projects</v-subheader>
        </v-col>
      </v-row>
    </div>

    <div class="d-flex col justify-end align-center right">
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addProjectDialog(true)"
            v-bind="attrs"
            v-on="on"
            title=""
            right
            fab
            small
            color="success white--text"
            style="margin: 5px"
            ><v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add New Institution</span>
      </v-tooltip>
    </div>


    <v-row class="mt-5">
      <v-card class="col-md-12">
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}%
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="success"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link to="/components/project_details">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-pencil-plus-outline</v-icon
                    >
                    Edit Institution</v-list-item-title
                  >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link to="">
                  <v-list-item-title
                    ><v-icon color="pink" style="height: 16px; font-size: 16px"
                      >mdi-delete</v-icon
                    >
                    Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      OpenNoticeDialog: false,
      OpenFeedbackEditor: false,
      OpenfileDialog: false,
      OpenDecisionEditor: false,
      filePreviewDialog: false,
      OpenPSARecommendationEditor: false,
      projectAddDialogData: false,
      OpenMovementsEditor: false,
      title: "",
      actionName: "PSA Analysis, Comments and Advice",
      appealTitle: "PSA Analysis, Comments and Advice",
      name: [],

      headers: [
        {
          text: "Instituion Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Shortform", value: "carbs" },
        { text: "Descriptions", value: "fat" },
        { text: "Actions", value: "actions" },
      ],

      desserts: [
        {
          name: "e-Govrnment Authority",
          carbs: 'eGA',
          fat: "Establishment of the National ICT Professional and Innovation Center",
          actions: "1%",
        },
        {
          name: "Ministry of ICT",
          carbs: 'MCIT',
          fat: "Establishment of the National ICT Professional and Innovation Center",
          actions: "1%",
        }
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
    };
  },
  methods: {
    openAppealAttachment(docname) {
      this.title = docname;
      this.OpenfileDialog = true;
    },

    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },

    addProjectDialog(data) {
      this.projectAddDialogData = data;
    },
  },
  computed: {
    appeal() {
      // var appealList = this.$store.state.AppealsStore.listOfAppeals;
      // const appeals = appealList.find(appeal => appeal.appealsUniqueId === this.appealId);
      return [];
    },
    appealId() {
      return this.$route.params.appealId;
    },
  },
  async mounted() {
    this.$store.dispatch("showLoadingPinner", true);
    await this.$store.dispatch("loadAppeals");
    this.$store.dispatch("showLoadingPinner", false);
  },
};
</script>



<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style>
.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}
.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}
.events-banner .prefix {
  color: #858585;
}
.events-banner .content {
  font-weight: bold;
  color: #273080;
}
.events-banner .value {
  color: #3a5831;
  font-size: larger;
}
.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}
.vue-xeditable-value {
  cursor: pointer;
  color: #17c;
  border-bottom: 1px dashed #07c;
  line-height: 22px;
}
h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}
h4 {
  margin-bottom: 0;
  color: #404040;
}
a {
  color: #07c;
}
pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}
.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}
pre code,
pre tt {
  background-color: transparent;
  border: none;
}
</style>


<style>
#div1,
#div2 {
  float: left;
  width: 100px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
</style>






